export class BaseSaleChannel
{
    #id    
    #profile
    #parameters
    #status
    _ref_id

    _channel_type = null

    name
    warehouse    

    get id() {
        return this.#id
    }

    get profile() {
        return this.#profile
    }

    get parameters() {
        return this.#parameters
    }

    get channel_type() {
        return this._channel_type
    }

    get status() {
        return this.#status
    }

    get ref_id() {
        return this._ref_id
    }

    get refID() {
        return this.ref_id
    }    

    constructor({ id, profile, name, warehouse, ref_id, parameters, status }) {
        this.#id = id
        this.#profile = profile
        this._ref_id = ref_id
        this.#status = status        
        
        this.name = name
        this.warehouse = warehouse
        
        if (parameters)
            this.#parameters = JSON.parse(parameters)

    }

    serialize() {
        return {
            name: this.name,
            warehouse_id: !!this.warehouse ? this.warehouse.id : null,            
            channel_type: this.channel_type,
            profile: this.profile
        }
    }
}