import { BaseSaleChannel } from "./BaseSaleChannel";

export class LineSaleChannel extends BaseSaleChannel
{
    channelSecret
    accessToken

    constructor(inputparameters) {
        super(inputparameters)

        this._channel_type = 'line'

        if (!!this.parameters && !!this.parameters.channelSecret) this.channelSecret = this.parameters.channelSecret

        if (!!this.parameters && !!this.parameters.accessToken) this.accessToken = this.parameters.accessToken
    }

    serialize() {
        return {
            ...super.serialize(),
            ref_id: this.ref_id,
            accessToken: this.accessToken,
            channelSecret: this.channelSecret
        }
    }
}