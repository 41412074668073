<template>
    <div>
        <div v-if="!!$attrs.value.accessToken">ขณะนี้ เชื่อมต่อกับ {{ $attrs.value.shopName }}</div>
        <v-btn 
        color="#FF6600" 
        class="white--text mt-2"
        @click="connectWithShopee"
        :loading="loading"        
        >กดที่นี่ เพื่อเชื่อมต่อ SHOPEE {{ !!$attrs.value.accessToken ? "อีกครั้ง" : "" }}</v-btn>        
        <div class="error--text mt-2">***การเชื่อมต่อสามารถคงอยู่ได้สูงสุดเพียง 365 วัน ท่านต้องมากดเชื่อมต่อที่นี่ทุกครั้งเมื่อครบกำหนด {{ $store.getters['app/GET_APP_MODE'] != 'create' ? " ด้วยการกดปุ่มข้างบน และทำตามขั้นตอนใหม่อีกครั้ง จากนั้นกดแก้ไข" : "" }} </div>        
    </div>
</template>
<script>
import { asyncGet } from '@/helpers/asyncAxios'
export default {
  data() {
    return {
      loading: false,
      popupInterval: null,
    }
  },
  mounted() {
    // Listen for messages from the popup
    // window.addEventListener('message', this.receiveMessage, false)
  },
  beforeDestroy() {
    // Clean up event listener when component is destroyed
    window.removeEventListener('message', this.receiveMessage)
  },
  methods: {
    async connectWithShopee() {
      this.loading = true

      window.addEventListener('message', this.receiveMessage, false)

      try {
        const response = await asyncGet('/setting/salechannel/getAuthLink')

        const popup = window.open(response, '_blank', 'width=800,height=600')

        // Optionally, check if popup is closed (if needed)
        const popupInterval = setInterval(() => {
          if (popup.closed) {
            clearInterval(popupInterval)
            console.log('Popup closed.')
            this.loading = false
          }
        }, 500)
      } catch (error) {
        console.log(error)
        this.$root.showCommonDialog('มีปัญหา', this.$errorParser(error))
      }
    },
    receiveMessage(event) {
      const allowedOrigins = [
        'http://localhost:81',
        'http://test.aoocommerce.com',
        'https://test.aoocommerce.com',
        'http://v2.aoocommerce.com',
        'https://v2.aoocommerce.com',
      ]

      if (!allowedOrigins.includes(event.origin)) {
        //console.warn('Origin not allowed:', event.origin)
        return
      }

      // Access the multiple data values sent from the popup
      const { error, access_token, refresh_token, expire_in, message, shop_id, shop_name } = event.data

      console.log(event.data)

      if (!!error) {
        this.$root.showCommonDialog('มีปัญหา', this.$errorParser(message))
      } else {
        this.$attrs.value.name = shop_name
        this.$attrs.value._ref_id = shop_id
        this.$attrs.value.shopName = shop_name
        this.$attrs.value.accessToken = access_token
        this.$attrs.value.refreshToken = refresh_token
        this.$attrs.value.expireAt = Date.now() + expire_in * 1000
      }
    },
  },
}
</script>