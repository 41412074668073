import { BaseSaleChannel } from "./BaseSaleChannel";

export class FBSaleChannel extends BaseSaleChannel
{
    #instagram
    #accessToken
    #instagramConnected = false
    
    profile

    useInstagram = false
    isInstagramDeleted = false

    // TODO : Seperate Instagram own class
    #instagram_id
    instagram_name
    instagram_warehouse
    instagram_profile

    get instagram() {
        return this.#instagram
    }

    get accessToken() {
        return this.#accessToken
    }    

    get hasInstagram() {
        return !!this.#instagram
    }

    get instagramConnected() {
        return this.#instagramConnected
    }

    get instagram_id() {
        if (this.#instagram_id) return this.#instagram_id

        if (!this.parameters.instagram_id) return null

        return this.parameters.instagram_id
    }

    
    constructor(params) {
        super(params)        

        this._channel_type = 'sc_fb'
        
        if (!!this.parameters && !!this.parameters.accessToken)
            this.#accessToken = this.parameters.accessToken

        if (!!this.parameters && !!this.parameters.instagram_id)
            this.#instagramConnected = true
    }

    setFacebookPage({ id, name, access_token, instagram, profile }) {
        this.setInstagram(instagram)
        this.#accessToken = access_token        
        this._ref_id = id

        this.name = name        
        this.profile = profile        
    }

    setInstagram(instagramValue) {
        this.#instagram = instagramValue        

        const { id, name, profile } = instagramValue

        this.#instagram_id = id
        this.instagram_name = name
        this.instagram_profile = profile

        this.isInstagramDeleted = false
    }

    serialize() {        
        const response = {
            ...super.serialize(),
            ref_id: this.refID,
            accessToken: this.accessToken,
        }

        if (this.useInstagram) {
            response.instagram_id = this.instagram_id
            response.instagram_name = this.instagram_name
            response.instagram_profile = this.instagram_profile
            response.instagram_warehouse = this.instagram_warehouse
        }

        return response
    }
}