import { BaseSaleChannel } from "./BaseSaleChannel";

export class ShopeeSaleChannel extends BaseSaleChannel
{    
    accessToken
    refreshToken    
    
    shopName

    #expired_at
    #refresh_before
    #refreshed_at

    constructor(inputparameters) {
        super(inputparameters)

        this._channel_type = 'shopee'        

        const { expired_at, refresh_before, refreshed_at } = inputparameters

        this.#expired_at = expired_at
        this.#refreshed_at = refreshed_at
        this.#refresh_before = refresh_before

        if (!!this.parameters && !!this.parameters.accessToken) this.accessToken = this.parameters.accessToken

        if (!!this.parameters && !!this.parameters.refreshToken) this.refreshToken = this.parameters.refreshToken            

        if (!!this.parameters && !!this.parameters.shopName) this.shopName = this.parameters.shopName        
    }

    serialize() {
        return {
            ...super.serialize(),
            ref_id: this.ref_id,
            shopName: this.shopName,
            accessToken: this.accessToken,
            refreshToken: this.refreshToken,
            expired_at: this.#expired_at,
            refresh_before: this.#refresh_before,
            refreshed_at: this.#refreshed_at
        }
    }
}