<template>
    <div>
        <template
          v-if="facebook.status != 'connected'"
        >
          <v-row>
              <v-col>
                  <v-btn
                      class="white--text"
                      color="#4267B2"
                      @click="reAuthWithFacebook"                    
                  >เชื่อมต่อ Facebook และ Instagram อีกครั้ง</v-btn>
              </v-col>                    
          </v-row>
          <v-row>
            <v-col class="error--text">
              ***ในกรณีที่ท่านต้องการเชื่อมต่อกับเพจของ Facebook ท่านจะต้องอนุญาติการเข้าถึงสิทธ์ต่างๆ ตามที่ทาง aoocommerce ร้องขอทั้งหมด
            </v-col>
          </v-row>
        </template>
        <template
          v-else
        >
          <v-row>
              <v-col cols="12" md="6">
                  <v-autocomplete
                  :items="items"
                  label="เลือกเพจ"
                  outlined
                  :value="$attrs.value"
                  @input="$emit('input', $event)"
                  item-text="name"
                  item-value="id"
                  dense
                  :rules="[validators.required]"
                  return-object
                  ref="autocomplete"
                  :disabled="!!defaultSelected"
                  >

                    <template v-slot:selection="{ item, index }">
                      <div class="d-flex align-center">
                        <v-img 
                        v-if="!!item.profile" 
                        :src="item.profile"
                        :max-width="25"
                        class="mr-2"
                        ></v-img> {{ item.name }}               
                      </div>
                    </template>                
                  </v-autocomplete>        
              </v-col>
          </v-row>
        </template>
    </div>    
</template>
<script>
import { asyncGet } from '@/helpers/asyncAxios'
import { required } from '@core/utils/validation'
export default {
  props: ['defaultSelected'],
  data() {
    return {
      items: [],
      facebook: {
        accessToken: null,
        status: null,
      },
      validators: {
        required,
      },
    }
  },
  created() {
    if (this.$store.getters['app/GET_APP_MODE'] == 'create') this.reAuthWithFacebook()
  },
  methods: {
    async reAuthWithFacebook() {
      var that = this
      window.FB.login(
        function (response) {
          const { authResponse, status } = response

          that.facebook.status = status

          if (!!authResponse && status == 'connected') {
            that.facebook.accessToken = authResponse.accessToken

            that.getPageList()
          }
        },
        {
          auth_type: 'rerequest',
          scope:
            'public_profile,email,pages_messaging,pages_show_list,pages_manage_metadata,pages_read_engagement,business_management,instagram_basic,instagram_manage_messages',
          return_scopes: true,
        },
      )
    },
    async getPageList() {
      this.$store.commit('app/SET_GLOBAL_LOADING', true)
      try {
        this.items = await asyncGet('/FBPages', { accessToken: this.facebook.accessToken })

        if (!!this.defaultSelected) {
          const found = this.items.find(item => item.id == this.defaultSelected)

          if (found) {
            this.$refs.autocomplete.selectItem(found)
          }
        }
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }
      this.$store.commit('app/SET_GLOBAL_LOADING', false)
    },
  },
}
</script>
