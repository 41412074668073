<template>
    <div>
        <h2>{{ $store.getters['app/GET_APP_MODE'] == 'create' ? "เพิ่ม" : "แก้ไข"}}ช่องทางการขาย</h2>
        <v-form v-model="isFormValid">
          <v-card class="mt-4">
            <v-card-title>ประเภทช่องทาง</v-card-title>

            <div class="pa-8">
              <v-row>
                <v-col cols="12" md="6">
                  <v-select
                    :items="items"
                    label="ประเภทช่องทางการขาย"
                    outlined
                    v-model="channel_type"
                    item-text="name"
                    item-value="id"
                    dense
                    :disabled="$store.getters['app/GET_APP_MODE'] != 'create'"
                  >
                    <template v-slot:selection="{ item, index }">
                      <div class="d-flex align-center">
                        <v-img 
                        v-if="!!item.prependImage" 
                        :src="item.prependImage"
                        :max-width="25"
                        class="mr-2"
                        ></v-img> {{ item.name }}               
                      </div>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <facebook-channel 
              v-if="channel_type == 'sc_fb'"
              v-model="page" 
              :defaultSelected="defaultSelected"
              />              
              <v-row v-if="salechannel.hasInstagram" no-gutters>
                <v-col cols="12" md="6">
                  <v-checkbox
                  label="ต้องการเชื่อม IG ที่ผูกกับเพจนี้ ?"
                  v-model="salechannel.useInstagram"
                  ></v-checkbox>                  
                </v-col>
              </v-row>
              <template v-else-if="channel_type == 'line'">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="salechannel.channelSecret"
                      outlined
                      label="Channel Secret"                      
                      :rules="[validators.required]"
                      hide-details="auto"          
                      dense  
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="salechannel.accessToken"
                      outlined
                      label="Channel access token (long-lived)"                               
                      :rules="[validators.required]"
                      hide-details="auto"          
                      dense  
                    ></v-text-field>
                  </v-col>
                </v-row>
              </template>
              <shopee-channel
              v-else-if="channel_type == 'shopee'"
              v-model="salechannel"
              ></shopee-channel>
            </div>
          </v-card>

          <v-card class="mt-4">
              <v-card-title>ช่องทางการขาย</v-card-title>

              <div class="pa-8">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="salechannel.name"
                      outlined
                      label="ชื่อช่องทาง"                               
                      :rules="[validators.required, validators.maxLengthValidator(salechannel.name, 50)]"
                      hide-details="auto"          
                      dense  
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <delay-autocomplete
                      outlined
                      hide-selected
                      cache-items
                      v-model="salechannel.warehouse"
                      label="ผูกกับคลัง"
                      placeholder="พิมพ์เพื่อค้นหาคลัง (เว้นว่างหากไม่เลือก)"
                      no-data-text="ไม่พบข้อมูล"
                      :url="'/setting/salechannel/warehouseList'"
                      dense
                      return-object
                      ref="salechannelAutocomplete"
                      :lazySearch="true"
                      item-text="name"
                      item-value="id"
                      :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                    ></delay-autocomplete>
                  </v-col>
                </v-row>
                <template v-if="channel_type == 'sc_fb' && salechannel.useInstagram && !salechannel.isInstagramDeleted">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="salechannel.instagram_name"
                        outlined
                        label="ชื่อช่องทาง IG"                               
                        :rules="[validators.required, validators.maxLengthValidator(salechannel.instagram_name, 50)]"
                        hide-details="auto"          
                        dense  
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                  <v-col cols="12" md="6">
                    <delay-autocomplete
                      outlined
                      hide-selected
                      cache-items
                      v-model="salechannel.instagram_warehouse"
                      label="ผูกกับคลัง (สำหรับช่องทาง IG)"
                      placeholder="พิมพ์เพื่อค้นหาคลัง (เว้นว่างหากไม่เลือก)"
                      no-data-text="ไม่พบข้อมูล"
                      :url="'/setting/salechannel/warehouseList'"
                      dense
                      return-object                      
                      :lazySearch="true"
                      item-text="name"
                      item-value="id"
                      :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                    ></delay-autocomplete>
                  </v-col>
                </v-row>
                </template>
                <template v-if="channel_type == 'sc_fb' && salechannel.instagramConnected && salechannel.isInstagramDeleted">
                  <h4 class="error--text">ช่องทาง Instagram ที่เคยเชื่อมต่อได้ถูกลบไปแล้ว สามารถเชื่อมต่อใหม่ได้อีกครั้งโดยการกดปุ่มเชื่อมต่อด้านบน</h4>
                </template>
                <template v-if="channel_type == 'sc_fb' && salechannel.instagramConnected && !salechannel.isInstagramDeleted">
                  <h4 class="success--text">ได้เชื่อมต่อกับช่องทาง Instagram แล้ว</h4>
                </template>
              </div>

              <v-card-actions>              
                <v-btn
                  color="primary"               
                  large          
                  :disabled="!isFormValid"
                  :loading="sendingData"
                  @click="submitForm"
                  v-if="$store.getters['app/GET_APP_MODE'] != 'read'"
                >
                  {{ $store.getters['app/GET_APP_MODE'] == 'create' ? "สร้าง" : "แก้ไข"}}
                </v-btn>

                <v-btn
                  color="secondary"               
                  outlined                 
                  large
                  :disabled="sendingData"
                  @click="$router.go(-1)"
                >
                  กลับ
                </v-btn>                
              </v-card-actions>
          </v-card>
        </v-form>
    </div>
</template>
<script>
import facebookSVG from '@/assets/images/3rdparty/facebook.svg'
import lineSVG from '@/assets/images/3rdparty/lineoa.svg'
import shopeeSVG from '@/assets/images/3rdparty/shopee.svg'
import { BaseSaleChannel } from '@/classes/salechannel/BaseSaleChannel'
import { FBSaleChannel } from '@/classes/salechannel/FBSaleChannel'
import { LineSaleChannel } from '@/classes/salechannel/LineSaleChannel'
import { ShopeeSaleChannel } from '@/classes/salechannel/ShopeeSaleChannel'
import { asyncGet, asyncPostAsJson } from '@/helpers/asyncAxios'
import { maxLengthValidator, required } from '@core/utils/validation'
import FacebookChannel from './components/FacebookChannel.vue'
import ShopeeChannel from './components/ShopeeChannel.vue'
export default {
  data() {
    return {
      isFormValid: false,
      sendingData: false,
      items: [
        {
          id: null,
          name: 'ทั่วไป',
        },
        {
          id: 'sc_fb',
          name: 'Facebook & Instagram',
          prependImage: facebookSVG,
        },
        {
          id: 'line',
          name: 'LineOA',
          prependImage: lineSVG,
        },
        {
          id: 'shopee',
          name: 'Shopee',
          prependImage: shopeeSVG,
        },
      ],
      page: null,
      defaultSelected: null,
      channel_type: null,
      salechannel: new BaseSaleChannel({}),
      validators: {
        required,
        maxLengthValidator,
      },
    }
  },
  watch: {
    page: function (value) {
      if (this.salechannel.channel_type != 'sc_fb') return

      this.salechannel.setFacebookPage(value)
    },
    channel_type(value) {
      const salechannel_id = this.$route.params.salechannel_id

      if (salechannel_id != 'create') return

      if (value == 'sc_fb') {
        this.salechannel = new FBSaleChannel({})
      } else if (value == 'line') {
        this.salechannel = new LineSaleChannel({})
      } else if (value == 'shopee') {
        this.salechannel = new ShopeeSaleChannel({})
      } else {
        this.salechannel = new BaseSaleChannel({})
      }
    },
  },
  async created() {
    const salechannel_id = this.$route.params.salechannel_id

    if (salechannel_id == 'create') {
      this.$store.commit('app/SET_APP_MODE', 'create')
      return
    } else if (this.$store.getters['auth/GET_ABILITY'].can('update', 'Setting'))
      this.$store.commit('app/SET_APP_MODE', 'edit')
    else this.$store.commit('app/SET_APP_MODE', 'read')

    this.$store.commit('app/SET_GLOBAL_LOADING', true)
    try {
      const response = await asyncGet('/setting/salechannel/' + salechannel_id)

      this.channel_type = response.channel_type
      this.defaultSelected = response.ref_id

      if (response.channel_type == 'sc_fb') {
        this.salechannel = new FBSaleChannel(response)

        if (this.salechannel.instagramConnected) {
          const responseIG = await asyncGet('/setting/salechannel/refID/' + this.salechannel.instagram_id)

          if (!!responseIG) this.salechannel.setInstagram(responseIG)
          else this.salechannel.isInstagramDeleted = true
        }
      } else if (response.channel_type == 'line') {
        this.salechannel = new LineSaleChannel(response)
      } else if (response.channel_type == 'shopee') {
        this.salechannel = new ShopeeSaleChannel(response)
      } else {
        this.salechannel = new BaseSaleChannel(response)
      }
    } catch (error) {
      console.log(error)
      this.$root.showCommonDialog('มีปัญหา', error)
    }
    this.$store.commit('app/SET_GLOBAL_LOADING', false)
  },
  methods: {
    async submitForm() {
      this.sendingData = true
      try {
        const sendData = this.salechannel.serialize()

        const salechannel_id = this.$route.params.salechannel_id

        if (salechannel_id == 'create') await asyncPostAsJson('/setting/salechannel', sendData)
        else {
          sendData['_method'] = 'PUT'
          await asyncPostAsJson('/setting/salechannel/' + salechannel_id, sendData)
        }

        this.$router.push({ name: 'salechannel' })
      } catch (error) {
        console.log(error)
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.sendingData = false
    },
  },
  components: {
    FacebookChannel,
    ShopeeChannel,
  },
}
</script>